import React from "react"
import { Link, graphql } from "gatsby"
//
// import { StaticImage } from "gatsby-plugin-image"
import BackgroundImage from "gatsby-background-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribtionForm from "../components/subscribtionForm"

// examples of cards and layout
// https://www.ordinarycoders.com/blog/article/17-tailwindcss-cards

const BlogIndex = ({ data, location }) => {
  const { title, description} = data.site.siteMetadata
  const posts = data.allMarkdownRemark.edges
  return (
    <Layout location={location} title={title} description={description}>
      <SEO title="Analysis" description="Long-form research" />
      <div className="lg:px-16 px-2 mt-4">
        <SubscribtionForm />
      </div>
      <div className="hidden" id="api-articles">{posts.length}</div>

      {/* <StaticImage
        src="./test.jpg"
        alt="A dinosaur"
        placeholder="blurred"
        layout="fixed"
        width={200}
        height={200}
      /> */}

      <main className="mx-2 py-10">
        {posts.map(({ node }) => {
          const postTitle = node.frontmatter.title || node.fields.slug

          const thumbnailBackground =
            node.frontmatter.thumbnail.childImageSharp.fluid

          return (
            <article
              className="mb-6 lg:mx-56 max-w-full lg:flex"
              key={node.fields.slug}
            >
              {
                // wrapping BackgroundImage with a or Link makes background image disappear!
                /* <a to={node.fields.slug}>
                <BackgroundImage
                  className="h-56 lg:h-auto lg:w-56 flex-none bg-cover rounded-t-md lg:rounded-t-none lg:rounded-l-md text-center overflow-hidden"
                  fluid={thumbnailBackground}
                />
              </a> */
              }

              <BackgroundImage
                className="h-56 border-mediumgrey p-10 lg:h-auto lg:w-56 flex-none bg-cover rounded-t-sm lg:border-r-0 border-r border-t border-l lg:border-t lg:border-b lg:border-l lg:rounded-t-none lg:rounded-l-sm text-center overflow-hidden"
                fluid={thumbnailBackground}
              />
            
              <Link to={node.fields.slug}>
                <div className="border-r border-b border-l border-mediumgrey lg:border-l-0 lg:border-t bg-white rounded-b-sm lg:rounded-b-none lg:rounded-r-sm p-4 flex flex-col justify-between leading-normal cursor-pointer">
                  <div className="mb-4">
                    <header>
                      <h3 className="w-full text-2xl font-serif font-black text-darkgrey leading-tight">
                        {postTitle}
                      </h3>
                      <h3 className="w-full mt-2 text-xs font-serif text-predarkgrey">
                        {node.frontmatter.date} <span> &#8212; </span>{" "}
                        {node.fields.readingTime.text}
                      </h3>
                    </header>

                    <section>
                      <p
                        className="w-full mt-2 text-base font-serif text-darkgrey"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                    </section>
                  </div>
                </div>
              </Link>
            </article>
          )
        })}
      </main>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { type: { eq: "blog" } } }
      ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
